// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LeadContactProviderRequest from "./requests/LeadContactProviderRequest.res.js";

var contactProviderBareMetal = LeadContactProviderRequest.BareMetal.exec;

export {
  contactProviderBareMetal ,
}
/* LeadContactProviderRequest Not a pure module */
