// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateProviderLeadRequest from "./requests/CreateProviderLeadRequest.res.js";

var create = CreateProviderLeadRequest.exec;

export {
  create ,
}
/* CreateProviderLeadRequest Not a pure module */
